header + .content-wrapper {
				padding-top: 10rem;
}

.content {
				$self: &;
				//overflow: hidden;
				&-wrapper {
								&-sidebar {
												padding-left: 15px;
												padding-right: 15px;
												#{ $self } {
																&-container {
																				background-color: $white;
																				box-shadow: (0 2px 4px rgba(0,0,0, 0.14));
																				-webkit-box-shadow: (0 2px 4px rgba(0,0,0, 0.14));
																				-moz-box-shadow: (0 2px 4px rgba(0,0,0, 0.14));
																}
												}
								}
				}
}

@include media-breakpoint-up(lg) {
				.content {
								$self: &;
								&-wrapper {
												&-sidebar {
																display: flex;
																
																> #{ $self } {
																				flex: 0 0 4.5*$block;
																				max-width: 4.5*$block;
																				margin-right: auto;
																}
												}
								}
				}
				.sidebar {
								flex: 0 0 2*$block;
								max-width: 2*$block;
								margin-right: .5*$block;
								margin-left: auto;
				}
}

@media (width: 1024px) {
				.content {
								$self: &;
								&-wrapper {
												&-sidebar {
																> #{ $self } {
																				flex: 0 0 4*$block;
																				max-width: 4*$block;
																}
												}
								}
				}
				.sidebar {
								margin-right: .2*$block;
				}
}

.shape {
				&-layout {
								&-1 {
												position: absolute;
												top: 9rem;
												left: 5%;
												z-index: -1;
												@include media-breakpoint-down(xs) {
																display: none;
												}
								}
								
								&-2 {
												position: absolute;
												top: 5rem;
												left: 0%;
												z-index: -1;
												@include media-breakpoint-up(md) {
																left: 35%;
												}
								}
								
								&-3 {
												position: absolute;
												top: 1rem;
												right: 33%;
												z-index: -1;
												@include media-breakpoint-down(xs) {
																display: none;
												}
								}
								
								&-4 {
												position: absolute;
												top: 1.5rem;
												right: 8%;
												
												z-index: -1;
								}
				}
}