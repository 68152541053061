.recommended {
				$self: &;
				&__title {
								font-family: HaveHeartOne;
								font-size: 1.75rem;
								line-height: 1.75rem;
								text-align: center;
								margin-bottom: 2rem;
				}
				&__block {
								height: 0;
								visibility: hidden;
								padding: 1rem;
								background-color: $yellow;
								margin-bottom: 2rem;
								@include transition(all .4s ease);
								@include border-radius(8px);
								@include media-breakpoint-up(lg) {
												padding: 1.8rem 1.2rem 2rem 2.5rem;
								}
								&__items {
												height: 0;
												visibility: hidden;
												opacity: 0;
												@include transition(all .4s ease);
												&-active {
																height: inherit;
																visibility: visible;
																opacity: 1;
																#{ $self } {
																				&__block {
																								height: inherit;
																								visibility: visible;
																				}
																}
												}
								}
								&__header {
												text-align: center;
												margin-bottom: 1.5rem;
												@include media-breakpoint-up(lg) {
																align-items: center;
																display: flex;
																text-align: left;
												}
								}
								&__avatar {
												max-width: 65px;
												height: auto;
												margin-bottom: 1rem;
												@include border-radius(50%);
												@include media-breakpoint-up(lg) {
																margin-right: 2rem;
																margin-bottom: 0;
												}
								}
								&__title {
												font-family: Walsheim;
												font-weight: 600;
												font-size: 1.5rem;
												line-height: 1.75rem;
								}
								&__description {
												padding-bottom: 2rem;
												border-bottom: 1px solid $border-color;
												
								}
								&__summary {
												padding-top: 2rem;
												display: flex;
												align-items: center;
												justify-content: space-between;
												flex-wrap: wrap;
												font-weight: 600;
												.btn {
																font-weight: normal;
																padding: .85rem 1.2rem;
												}
								}
								&__options {
												>div:nth-child(2) {
																margin-top: 1rem;
												}
								}
								&__days, &__persons {
												display: flex;
												align-items: center;
												justify-content: space-between;
												margin-bottom: 1rem;
												&-label {
																margin-right: 1rem;
												}
												&-input {
																display: flex;
												}
												&-result {
																min-width: 60px;
																text-align: center;
												}
												&-btn {
																position: relative;
																cursor: pointer;
																color: $blue;
																display: flex;
																align-items: center;
																justify-content: center;
																width: 32px;
																height: 32px;
																border: 1px solid $blue;
																@include border-radius(50%);
																z-index: 1;
																overflow: hidden;
																@include transition(all .3s ease);
																&:hover, &:focus, &:active {
																				text-decoration: none;
																				background-color: $blue;
																				color: $white;
																}
																&:before {
																				background: $blue;
																}
																&-disabled {
																				opacity: .3;
																				&:hover, &:focus, &:active {
																								background: none;
																								color: $blue;
																				}
																}
												}
												@include media-breakpoint-up(lg) {
																margin-bottom: 0;
												}
								}
								&__extra {
												cursor: pointer;
												padding: 1.5rem;
												background-color: $yellow;
												margin-bottom: 2rem;
												font-family: Walsheim;
												font-size: 1.125rem;
												text-align: center;
												line-height: 1.375rem;
												color: $blue;
												@include border-radius(8px);
												svg {
																margin-left: 1rem;
																transition: ease 0.4s;
																g > g {
																				fill: $blue;
																}
												}
												&-active {
																color: $black;
																background-color: $blue-light;
																svg {
																				transform: rotate(135deg);
																				g > g {
																								fill: $black;
																				}
																}
												}
								}
								&__add {
												margin-right: .75rem;
												svg {
																width: .85rem;
																height: auto;
																g > g {
																				fill: $white;
																}
												}
								}
				}
}