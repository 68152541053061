.footer {
				padding: 2rem 1rem;
				@include media-breakpoint-up(sm) {
								padding: 6rem 5rem;
				}
				
				&-container {
								padding-top: 4rem;
								display: flex;
								flex-direction: column;
								font-family: Walsheim;
								color: $primary;
								align-items: center;
								text-align: center;
								border-top: 1px solid $border-color;
								@include media-breakpoint-down(sm) {
												> div {
																margin-bottom: 2rem;
												}
								}
								@include media-breakpoint-up(sm) {
												flex-direction: row;
												text-align: left;
												align-items: unset;
								}
				}
				
				&-logo {
								&-svg {
												width: 92px;
												display: block;
												
												svg {
																width: 100%;
																height: auto;
												}
								}
				}
				
				&-address {
								flex: 1 0 0px;
								@include media-breakpoint-up(sm) {
												padding-left: 2rem;
								}
				}
				
				&-powered {
								display: flex;
								align-items: flex-end;
				}
}