.hero {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 100%;
				width: 100%;
				
				&-logo {
								z-index: 0;
								
								&-powered {
												position: absolute;
												top: 2.5rem;
												right: 3rem;
												z-index: 3;
								}
				}
				
				&-btn {
								z-index: 3;
								margin-top: 2rem;
				}
				
				&-shapes {
								&-back {
												position: absolute;
												height: 100%;
												width: 100%;
												z-index: -1;
												
												&-1 {
																position: absolute;
																bottom: 40%;
																right: 62%;
												}
												
												&-2 {
																position: absolute;
																top: 58%;
																left: 50%;
												}
												
												&-3 {
																position: absolute;
																top: 1%;
																right: 58%;
												}
												
												&-4 {
																position: absolute;
																top: 7%;
																left: 55%;
												}
												
												&-5 {
																position: absolute;
																bottom: 62%;
																right: 87%;
												}
												
												&-6 {
																position: absolute;
																bottom: 53%;
																left: 57%;
												}
												
												&-7 {
																position: absolute;
																top: 45%;
																right: 80%;
												}
												
												&-8 {
																position: absolute;
																top: 55%;
																left: 83%;
												}
								}
								
								&-front {
												z-index: 2;
												position: absolute;
												height: 100%;
												width: 100%;
												
												&-1 {
																position: absolute;
																bottom: 60%;
																left: 52%;
																z-index: 1;
												}
												
												&-2 {
																position: absolute;
																top: 69%;
																right: 63%;
												}
												
												&-3 {
																position: absolute;
																bottom: 75%;
																right: 90%;
												}
												
												&-4 {
																position: absolute;
																bottom: 76%;
																right: 67%;
												}
												
												&-5 {
																position: absolute;
																top: 2%;
																right: 23%;
												}
												
												&-6 {
																position: absolute;
																bottom: 55%;
																left: 80%;
												}
												
												&-7 {
																position: absolute;
																bottom: 55%;
																right: 72%;
												}
												
												&-8 {
																position: absolute;
																top: 46%;
																right: 85%;
												}
												
												&-9 {
																position: absolute;
																top: 82%;
																right: 63%;
												}
												
												&-10 {
																position: absolute;
																top: 72%;
																left: 72%;
												}
												
												&-11 {
																position: absolute;
																top: 84%;
																left: 67%;
												}
												
												&-12 {
																position: absolute;
																top: 63%;
																left: 88%;
												}
												
												&-animate {
																.hero-shapes-front {
																				&-1 {
																								animation: anim1 ease 100s infinite;
																				}
																				
																				&-2 {
																								animation: anim2 ease 8s infinite;
																				}
																				
																				&-3 {
																								animation: anim6 ease 5s infinite;
																				}
																				
																				&-4 {
																								animation: anim3 ease 7s infinite;
																				}
																				
																				&-5 {
																								animation: anim4 ease 15s infinite;
																				}
																				
																				&-6 {
																								animation: anim3 ease 8s infinite;
																				}
																				
																				&-7 {
																								animation: anim5 linear 120s infinite;
																				}
																				
																				&-8 {
																								animation: anim7 linear 120s infinite;
																				}
																				
																				&-9 {
																								animation: anim10 ease 40s infinite;
																				}
																				
																				&-10 {
																								animation: anim8 ease 4s infinite;
																				}
																				
																				&-11 {
																								animation: anim9 ease 60s infinite;
																				}
																				
																				&-12 {
																								animation: anim11 ease 20s infinite;
																				}
																}
																
																.isvg {
																				@include transition(all 1s ease);
																}
												}
								}
				}
}

@keyframes anim1 {
				0% {
								margin-bottom: 0;
								margin-left: 0;
								@include transform(rotate(0deg));
				}
				50% {
								margin-bottom: 30px;
								margin-left: 30px;
				}
				100% {
								margin-bottom: 0;
								margin-left: 0;
								@include transform(rotate(360deg));
				}
}

@keyframes anim2 {
				0% {
								margin-top: 15px;
								margin-right: 15px;
				}
				50% {
								margin-top: 0;
								margin-right: 0;
				}
				100% {
								margin-top: 15px;
								margin-right: 15px;
				}
}

@keyframes anim3 {
				0% {
								margin-bottom: 0;
				}
				50% {
								margin-bottom: 25px;
				}
				100% {
								margin-bottom: 0;
				}
}

@keyframes anim4 {
				0% {
								margin-top: 0;
								margin-right: 0;
				}
				50% {
								margin-top: 10px;
								margin-right: 50px;
				}
				100% {
								margin-top: 0;
								margin-right: 0;
				}
}

@keyframes anim5 {
				0% {
								margin-bottom: 0;
								margin-right: 0;
								@include transform(rotate(0deg));
				}
				50% {
								margin-bottom: 35px;
								margin-right: 35px;
				}
				100% {
								margin-bottom: 0;
								margin-right: 0;
								@include transform(rotate(360deg));
				}
}

@keyframes anim6 {
				0% {
								margin-bottom: 0;
								margin-right: 0;
				}
				50% {
								margin-bottom: 15px;
								margin-right: 15px;
								
				}
				100% {
								margin-bottom: 0;
								margin-right: 0;
				}
}

@keyframes anim7 {
				0% {
								margin-top: 0;
								margin-right: 0;
								@include transform(rotate(360deg));
				}
				50% {
								margin-top: 100px;
								margin-right: 65px;
				}
				100% {
								margin-top: 0;
								margin-right: 0;
								@include transform(rotate(0deg));
				}
}

@keyframes anim8 {
				0% {
								margin-top: 15px;
								margin-left: 15px;
				}
				50% {
								margin-top: 0;
								margin-left: 0;
				}
				100% {
								margin-top: 15px;
								margin-left: 15px;
				}
}

@keyframes anim9 {
				0% {
								margin-top: 0;
								margin-left: 0;
								@include transform(rotate(360deg));
				}
				50% {
								margin-top: 70px;
								margin-left: 5%;
				}
				100% {
								margin-top: 0;
								margin-left: 0;
								@include transform(rotate(0deg));
				}
}

@keyframes anim10 {
				0% {
								margin-top: 0;
								@include transform(rotate(0deg));
				}
				50% {
								margin-top: 5%;
								@include transform(rotate(90deg))
				}
				100% {
								margin-top: 0;
								@include transform(rotate(0deg));
				}
}

@keyframes anim11 {
				0% {
								margin-top: 0;
								margin-left: 0;
				}
				50% {
								margin-top: 5%;
								margin-left: 5%;
				}
				100% {
								margin-top: 0;
								margin-left: 0;
				}
}