.sidebar {
				&__title {
								font-size: 3rem;
								line-height: 3.75rem;
								margin-bottom: 2rem;
				}
				
				&__img {
								margin-top: 4.5rem;
								margin-bottom: 2rem;
				}
				
				&__content {
								position: relative;
								box-shadow: (0px 2px 4px rgba($black, 0.5));
								-webkit-box-shadow: (0px 2px 4px rgba($black, 0.5));
								-moz-box-shadow: (0px 2px 4px rgba($black, 0.5));
								
								&-item {
												padding: 2rem;
												background-color: $white;
												
												&:not(:last-child) {
																border-bottom: 1px solid $border-color;
												}
												
												&-wrapper {
																@include media-breakpoint-down(md) {
																				margin-bottom: 2rem;
																}
																&:after {
																				content: '';
																				display: block;
																				height: 1.4rem;
																				width: 100%;
																				background: transparent;
																}
												}
												
												&-title {
																font-family: Walsheim;
																font-size: 1.5rem;
																line-height: 2rem;
																
																&-wrapper {
																				display: flex;
																}
												}
												
												&-icon {
																margin-right: 1rem;
												}
												
												&-date {
																margin-left: 3rem;
												}
								}
								
								&-end {
												position: absolute;
												content: '';
												bottom: -10px;
												left: 0;
												right: 0;
												height: 2rem;
												background-position: left bottom;
												background-size: contain;
								}
				}
}