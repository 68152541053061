.details {
				$self: &;
				&__content {
								&__block {
												padding: 2rem 1rem;
												@include media-breakpoint-up(lg) {
																padding: 3rem;
																&-success, &-fail {
																				padding: 2.5rem 3rem 1rem;
																}
												}
												&-success {
																background-color: $green;
																color: $white;
												}
												&-fail {
																background-color: $pink;
																color: $white;
												}
												form {
																#{ $self } {
																				&__terms {
																								@include media-breakpoint-up(sm) {
																												display: inline-flex;
																												width: calc(100% - 12rem);
																								}
																								label {
																												cursor: pointer;
																												padding: 0.6875rem 0;
																												flex: 1 0 0px;
																												width: 100%;
																												max-width: 100%;
																												justify-content: left;
																												font-family: inherit;
																												font-weight: normal;
																												a {
																																margin-left: .25rem;
																																color: $black;
																																text-decoration: underline;
																												}
																								}
																				}
																				&__form {
																								&-btn {
																												display: inline-flex;
																												float: right;
																								}
																				}
																}
																.btn {
																				&-back {
																								height: 47px;
																								align-items: center;
																				}
																}
												}
								}
								&__message {
												padding: 2rem 1rem;
												color: $white;
												margin-bottom: 2.25rem;
												@include media-breakpoint-up(lg) {
																padding: 2.5rem 3rem 1rem;
												}
												&-success {
																background-color: $green;
																
												}
												
												&-fail {
																background-color: $pink;
												}
												&-title {
																font-family: Walsheim;
																font-weight: bold;
																font-size: 1.5rem;
																line-height: 1.75rem;
																margin-bottom: 1rem;
												}
												p {
																line-height: 1.75rem;
												}
								}
								&__title {
												font-family: HaveHeartOne;
												font-size: 2.625rem;
												line-height: 3.25rem;
												text-align: center;
												margin-bottom: 2rem;
								}
								&__tips {
												padding: 1rem;
												background-color: $white;
												box-shadow: (0px 2px 4px rgba($black, 0.5));
												-webkit-box-shadow: (0px 2px 4px rgba($black, 0.5));
												-moz-box-shadow: (0px 2px 4px rgba($black, 0.5));
												&-container {
																padding: 2rem 0rem 3.5rem;
																text-align: center;
																color: $white;
																position: relative;
																background-repeat: no-repeat;
																background-position: center;
																background-size: cover;
																&:before {
																				content: '';
																				position: absolute;
																				left: 0;
																				top: 0;
																				right: 0;
																				bottom: 0;
																				background: rgba(0,0,0, .61); /* Old browsers */
																				background: -moz-linear-gradient(top,  rgba(0,0,0, .61) 0%, rgba(0,0,0, 0) 100%); /* FF3.6-15 */
																				background: -webkit-linear-gradient(top,  rgba(0,0,0, .61) 0%, rgba(0,0,0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
																				background: linear-gradient(to bottom,  rgba(0,0,0, .61) 0%, rgba(0,0,0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
																}
																>div, p {
																				z-index: 1;
																				position: relative;
																}
																p {
																				line-height: 1.75rem;
																				font-style: italic;
																				font-weight: bold;
																				margin-bottom: 3rem;
																}
																.btn {
																				@include media-breakpoint-up(sm) {
																								padding: 1rem 3rem;
																				}
																}
												}
								}
				}
}