@import 'variables';
@import 'mixins';
@import '3rdparty/3rdparty';
@import 'pages';
@import 'components';
@import 'fonts';

body {
				margin: 0;
				background-color: $background-color;
				font-family: $font-family;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
				font-family: $headings-font-family;
}

.img-responsive {
				width: 100%;
				height: auto;
}

.divider {
				height: 1px;
				background-color: rgba($white, .32);
				margin: 2rem 0;
}

ul.checks {
				list-style-type: none;
				margin: 0;
				padding-inline-start: 0;
				
				li {
								background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='24px' height='25px' viewBox='0 0 24 25' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com --%3E%3Ctitle%3EAssets/Icons/Checkitem%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Site-design' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='2---home-scroll' transform='translate(-161.000000, -2362.000000)'%3E%3Cg id='Features' transform='translate(-905.000000, 956.000000)'%3E%3Cg id='Config' transform='translate(1065.000000, 871.000000)'%3E%3Cg id='Select-item' transform='translate(0.500000, 110.000000)'%3E%3Cg id='Items' transform='translate(0.250000, 422.324468)'%3E%3Cg id='Assets/Icons/Checkitem' transform='translate(0.000000, 3.000000)'%3E%3Cg%3E%3Ccircle id='Oval' fill='%2354D69E' cx='12.1818182' cy='12.1276596' r='11.6363636'%3E%3C/circle%3E%3Cg id='Assets/Icons/Check' transform='translate(5.090909, 6.545455)' fill='%23FFFFFF'%3E%3Cg id='Path'%3E%3Cpolygon points='11.7666629 0 5.2206047 6.69668421 2.15436954 3.79557895 4.4408921e-16 5.94694737 5.2206047 11 13.9216125 2.15078947'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
								background-repeat: no-repeat;
								padding-left: 3rem;
								margin-bottom: 1.5rem;
								font-size: 1.125rem;
								font-weight: 600;
				}
}

@media (max-width: 320px) {
				.row {
								margin: 0px;
				}
}