form {
				fieldset {
								margin-bottom: 2rem;
								label {
												display: block;
												font-family: Walsheim;
												font-weight: bold;
												margin: 0;
												height: 46px;
												&.required:after {
																color: red;
																content: " *";
																font-size: 1.2rem;
																margin-right: 1rem;
												}
								}
								input {
												height: 46px;
												background-color: rgba($black, 0.04);
												border: none;
												-webkit-appearance: none;
												-moz-appearance: none;
												appearance: none;
												box-shadow: (inset 1px 1px 2px rgba($black, 0.14));
												-webkit-box-shadow: (inset 1px 1px 2px rgba($black, 0.14));
												-moz-box-shadow: (inset 1px 1px 2px rgba($black, 0.14));
												@include border-radius(4px);
												&:focus {
																outline: none;
												}
												&[type=text], &[type=email] {
																display: block;
																width: 100%;
																padding: 0.6875rem 1rem;
												}
												&[type=checkbox] {
																position: relative;
																width: 1.5rem;
																height: 1.5rem;
																margin-right: 1.5rem;
																&:checked {
																				&:after {
																								content: '';
																								position: absolute;
																								left: 2px;
																								top: 2px;
																								right: 2px;
																								bottom: 2px;
																								background-repeat: no-repeat;
																								background-position: center;
																								background-size: contain;
																								background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOSAyMS4wMzVsLTktOC42MzggMi43OTEtMi44NyA2LjE1NiA1Ljg3NCAxMi4yMS0xMi40MzYgMi44NDMgMi44MTd6Ii8+PC9zdmc+");
																				}
																}
												}
								}
				}
				@include media-breakpoint-up(sm) {
								fieldset {
												label {
																display: inline-flex;
																width: 12rem;
																flex: 0 0 12rem;
																max-width: 12rem;
																justify-content: space-between;
																align-items: center;
												}
												input {
																&[type=text], &[type=email] {
																				display: inline-flex;
																				width: calc(100% - 12rem);
																}
												}
								}
				}

}