.btn {
				position: relative;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				font-family: Helvetica;
				font-size: 1.125rem;
				line-height: 1.125rem;
				text-align: center;
				vertical-align: middle;
				user-select: none;
				background-color: transparent;
				padding: .85rem 1.5rem .85rem 1rem;
				cursor: pointer;
				z-index: 1;
				overflow: hidden;
				@include transition(all .25s ease);
				@include border-radius(.25rem);
				
				&:hover, &:focus, &:active {
								text-decoration: none;
								
								&:before {
												bottom: 0;
								}
				}
				
				&:focus {
								outline: 0;
								box-shadow: $btn-focus-box-shadow;
				}
				
				&:before {
								content: "";
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 100%;
								z-index: -1;
								@include transition(all 0.15s ease);
				}
				
				&-black {
								background-color: $black;
								border-color: $black;
								color: $white;
				}
				
				&-blue {
								background-color: $blue;
								border-color: $blue;
								color: $white;
								
								&:hover, &:focus, &:active {
												color: $white;
								}
								
								&:before {
												background: darken($blue, 10%);
								}
				}
				
				&-pink {
								background-color: $pink;
								color: $white;
								
								&:hover, &:focus, &:active {
												color: $white;
								}
								
								&:before {
												background: $black;
								}
				}
				
				&-icon {
								display: inline-block;
								margin-right: $default-padding;
								
								svg {
												g > g {
																fill: $white;
												}
								}
				}
				&-back {
								display: inline-flex;
								color: $primary;
								cursor: pointer;
								@include transition(all .2s ease);
								
								&-icon {
												margin-right: 1rem;
												
												svg {
																g > g {
																				fill: $primary;
																}
												}
								}
								
								&:hover, &:focus, &:active {
												color: $black;
												
												svg {
																g > g {
																				fill: $black;
																}
												}
								}
				}
				&-wrapper {
								display: inherit;
								flex-flow: inherit;
								justify-content: inherit;
								align-items: inherit;
				}
}