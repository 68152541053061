.datepicker {
    max-width: 840px;
    margin: auto;
    background-color: $white;
    font-family: Walsheim;
    z-index: 1;
    padding-bottom: 2rem;
    box-shadow: (2px 2px 9px rgba($black, 0.11));
    -webkit-box-shadow: (2px 2px 9px rgba($black, 0.11));
    -moz-box-shadow: (2px 2px 9px rgba($black, 0.11));
    //td {
    //  background-color: $grey;
    //  border: 1px solid $grey;
    //}
    .CalendarMonth {
        &_caption {
            padding-bottom: 3.8125rem;
            color: $black;
            text-transform: capitalize;
        }
    }
    
    .DayPicker {
        margin: auto;
        padding: 1rem 0;
        
        &_weekHeader {
            color: $black;
            
            &_ul {
                font-size: $font-size-base;
                
                small {
                    font-size: 100%;
                }
            }
        }
    }
    
    .Calendar {
        &Day {
            font-size: 1rem;
            
            &__default {
                background-color: $white;
                border-color: $grey;
                
                &:hover {
                    background: $blue-light;
                }
            }
            
            &__highlighted {
                &_calendar {
                    border: 2px dashed rgba($black, .54);
                    
                    &:hover {
                        border: 2px dashed rgba($black, .54);
                    }
                }
            }
            
            &__selected {
                background: $primary;
                border-color: $grey;
                
                &:hover, &:active {
                    background: $primary;
                    border-color: $grey;
                }
                
                &_span {
                    background: $blue-light;
                    border-color: $grey;
                    color: $black;
                    
                    &:hover, &:active {
                        background: $blue-light;
                        border-color: $grey;
                        color: $black;
                    }
                }
            }
            
            &__hovered {
                &_span {
                    background: $blue-light;
                    border-color: $grey;
                    color: $black;
                    
                    &:hover, &:active {
                        background: $blue-light;
                        border-color: $grey;
                        color: $black;
                    }
                }
            }
            
            &__blocked {
                &_calendar {
                    background: $grey;
                    color: $black;
                    border-color: #cccccc;
                    
                    &:hover, &:active {
                        background: $grey;
                        color: $black;
                        border-color: #cccccc;
                    }
                }
            }
        }
    }
    
    &__wrapper {
        max-width: 840px;
    }
    
    &__modal {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 3;
        display: flex;
        justify-content: center;
        @include transition(all .5s ease);
        
        .triangle {
            z-index: -1;
        }
        
        &.active {
        }
        
        &-top {
            bottom: 97px;
            opacity: 0;
            padding-bottom: 2rem;
            visibility: hidden;
            
            &.active {
                opacity: 1;
                visibility: visible;
                //bottom: 97px;
                
                .triangle {
                    z-index: 1;
                    opacity: 1;
                    width: 3rem;
                    height: 3rem;
                    position: absolute;
                    bottom: 10px;
                    right: 36%;
                    
                    &:before {
                        content: '';
                        width: 3rem;
                        height: 3rem;
                        display: block;
                        background: $white;
                        transform: rotate(45deg);
                        box-shadow: (2px 2px 9px rgba($black, 0.11));
                        -webkit-box-shadow: (2px 2px 9px rgba($black, 0.11));
                        -moz-box-shadow: (2px 2px 9px rgba($black, 0.11));
                    }
                    
                    &:after {
                        content: '';
                        z-index: 1;
                        position: absolute;
                        width: 5rem;
                        height: 2.5rem;
                        display: block;
                        bottom: 1.375rem;
                        right: -1rem;
                        background: $white;
                    }
                }
            }
        }
        
        &-bottom {
            top: -100vh;
            bottom: 100%;
            z-index: -1;
            opacity: 0;
            padding-top: 2rem;
            visibility: hidden;
            
            &.active {
                top: 97px;
                bottom: 0;
                opacity: 1;
                visibility: visible;
                
                .triangle {
                    z-index: 1;
                    opacity: 1;
                    width: 3rem;
                    height: 3rem;
                    position: absolute;
                    top: 10px;
                    right: 36%;
                    
                    &:before {
                        content: '';
                        width: 3rem;
                        height: 3rem;
                        display: block;
                        background: $white;
                        transform: rotate(45deg);
                        box-shadow: (2px 2px 9px rgba($black, 0.11));
                        -webkit-box-shadow: (2px 2px 9px rgba($black, 0.11));
                        -moz-box-shadow: (2px 2px 9px rgba($black, 0.11));
                    }
                    
                    &:after {
                        content: '';
                        z-index: 1;
                        position: absolute;
                        width: 5rem;
                        height: 2.5rem;
                        display: block;
                        top: 1.375rem;
                        right: -1rem;
                        background: white;
                    }
                }
            }
        }
    }
}

.btn-availability {
    font-family: Walsheim;
    padding: .85rem 2rem 1rem;
    z-index: 2;
    
    &-wrapper {
        text-align: right;
        margin-right: 42px;
    }
    
    &-icon {
        margin-left: 1rem;
        
        svg {
            g > g {
                fill: $white;
            }
        }
    }
}
