.header {
				$self: &;
				z-index: 3;
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				display: flex;
				height: 97px;
				background-color: transparent;
				font-family: Walsheim;
				font-size: 1rem;
				border-bottom: 1px solid rgba($black, .14);
				
				> div {
								display: flex;
								height: 100%;
								align-items: center;
								@include media-breakpoint-down(xs) {
												padding: 1rem;
												margin: 0;
												flex: 1 0 0px;
												justify-content: center;
								}
				}
				
				&-sticky {
								background-color: $white;
								box-shadow: (0px 2px 4px rgba($black, 0.11));
								-webkit-box-shadow: (0px 2px 4px rgba($black, 0.11));
								-moz-box-shadow: (0px 2px 4px rgba($black, 0.11));
								
								.header-back {
												&:hover, &:focus, &:active {
																background-color: $grey-light;
												}
								}
				}
				
				&-back {
								color: $primary;
								padding: 0 2rem 0 3.5rem;
								cursor: pointer;
								border-right: 1px solid rgba($black, .14);
								@include transition(all .2s ease);
								
								&-icon {
												margin-right: 1rem;
												
												svg {
																g > g {
																				fill: $primary;
																}
												}
								}
								
								&:hover, &:focus, &:active {
												background-color: $blue-light;
												color: $black;
												
												svg {
																g > g {
																				fill: $black;
																}
												}
								}
				}
				
				&-title {
								padding-left: 3rem;
				}
}