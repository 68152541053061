.home {
    overflow: hidden;
    
    &__menu-open {
        overflow: hidden;
    }
    
    .scroll {
        cursor: pointer;
        position: absolute;
        z-index: 3;
        width: 2.25rem;
        bottom: 2.5rem;
    }
    
    .block {
        &__top {
            height: 100vh;
            overflow: hidden;
            @include transition(height .75s ease, opacity .5s ease);
            @include media-breakpoint-up(lg) {
                height: calc(100vh - 97px);
            }
            
            &-hidden {
                height: 0;
                opacity: 0;
                @include transition(height 1s ease, opacity 2s ease);
            }
        }
        
        &__insight {
            position: relative;
            padding-top: 150px;
            padding-bottom: $default-padding * 5;
            @include media-breakpoint-down(sm) {
                &-left {
                    &-insight {
                        &-1 {
                            position: absolute;
                            top: -23%;
                            right: 43%;
                        }
                        
                        &-2 {
                            position: absolute;
                            top: -29%;
                            right: -12%;
                            z-index: -1;
                        }
                        
                        &-3 {
                            position: absolute;
                            top: -20%;
                            left: -15%;
                        }
                        
                        &-4 {
                            position: absolute;
                            bottom: -17%;
                            right: 6%;
                        }
                    }
                }
                &-right {
                    display: none;
                }
            }
            @include media-breakpoint-up(sm) {
                &-left {
                    h1 {
                        font-size: 4.5rem;
                        line-height: 4.5rem;
                        margin-bottom: 2rem;
                    }
                    
                    p {
                        margin-bottom: 2rem;
                    }
                    
                    &-insight {
                        &-1, &-2, &-3, &-4 {
                            display: none;
                        }
                    }
                }
                &-right {
                    &-insight {
                        &-1 {
                            position: absolute;
                            top: -28%;
                            right: 55%;
                        }
                        
                        &-2 {
                            position: absolute;
                            top: -28%;
                            right: 0;
                        }
                        
                        &-3 {
                            position: absolute;
                            top: 3%;
                            right: 67%;
                        }
                        
                        &-4 {
                            position: absolute;
                            top: 3%;
                            right: 40%;
                            z-index: -1;
                        }
                        
                        &-5 {
                            position: absolute;
                            top: 18%;
                            right: 10%;
                        }
                        
                        &-6 {
                            position: absolute;
                            top: 28%;
                            right: 42%;
                        }
                        
                        &-7 {
                            position: absolute;
                            top: 70%;
                            right: 60%;
                        }
                        
                        &-8 {
                            position: absolute;
                            top: 50%;
                            right: 25%;
                        }
                        
                        &-9 {
                            position: absolute;
                            top: 90%;
                            right: 70%;
                        }
                    }
                }
            }
            @include media-breakpoint-up(lg) {
                padding-top: 200px;
                &-left {
                    max-width: calc(1140px / 2);
                    margin-left: auto;
                }
            }
        }
        
        &__research {
            padding-bottom: 1rem;
            
            &-left {
            }
            
            &-right {
                @include media-breakpoint-up(sm) {
                    max-width: calc(1140px / 2);
                    margin-right: auto;
                    .block__text {
                        margin-left: auto;
                    }
                    h2 {
                        font-size: 3rem;
                        line-height: 3rem;
                        margin-bottom: 2rem;
                        margin-top: 8rem;
                    }
                    p {
                        margin-bottom: 2rem;
                    }
                }
            }
        }
        
        &__cfg {
            background: $pink;
            @include gradient-directional($blue, $pink, -45deg);
            @include media-breakpoint-up(sm) {
                background: -moz-linear-gradient(145deg, $pink 0%, $pink 19%, $blue 100%);
                background: -webkit-linear-gradient(145deg, $pink 0%, $pink 19%, $blue 100%);
                background: linear-gradient(145deg, $pink 0%, $pink 19%, $blue 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f25a7e", endColorstr="#3559fe", GradientType=1);
            }
            color: $white;
            
            &-title {
                font-family: HaveHeartOne;
                font-size: 2.625rem;
                text-align: center;
                margin-bottom: 3rem;
                @include media-breakpoint-up(sm) {
                    margin-top: 3rem;
                }
            }
            
            &-shape {
                &-top {
                    position: relative;
                    height: 10rem;
                    overflow: hidden;
                    
                    &:before, &:after {
                        content: '';
                        display: block;
                        height: 10rem;
                        position: absolute;
                        background-color: $background-color;
                        width: 100%;
                    }
                    
                    &:before {
                        right: 33%;
                        bottom: 58%;
                        @include transform(rotate(-4deg));
                    }
                    
                    &:after {
                        left: 36%;
                        bottom: 47%;
                        @include transform(rotate(13deg));
                    }
                }
            }
            
            &-shape {
                &-bottom {
                    position: relative;
                    height: 14rem;
                    overflow: hidden;
                    
                    &:before, &:after {
                        content: '';
                        display: block;
                        height: 14rem;
                    }
                    
                    &:before, &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        background-color: $background-color;
                        width: 100%;
                    }
                    
                    &:before {
                        right: 61%;
                        top: 39%;
                        @include transform(rotate(17deg));
                        @include media-breakpoint-up(xl) {
                            right: 36%;
                            @include transform(rotate(14deg));
                        }
                    }
                    
                    &:after {
                        left: 28%;
                        top: 58%;
                        @include transform(rotate(-8deg));
                    }
                }
            }
            
            .row {
                @include media-breakpoint-up(md) {
                    margin: 0 -40px;
                }
            }
        }
        
        &__bottom {
            position: relative;
            display: flex;
            flex-direction: column;
            min-height: 880px;
            text-align: center;
            align-items: center;
            justify-content: center;
            
            &-logo {
                margin-bottom: 4rem;
            }
            
            &-shape {
                &-1 {
                    position: absolute;
                    bottom: 95%;
                    left: 17%;
                    @include media-breakpoint-up(xl) {
                        bottom: 92%;
                        left: 38%;
                    }
                }
                
                &-2 {
                    position: absolute;
                    bottom: 106%;
                    right: 7%;
                    @include media-breakpoint-up(sm) {
                        bottom: 102%;
                        right: 37%;
                    }
                    @include media-breakpoint-up(xl) {
                        right: 41%;
                    }
                }
                
                &-3 {
                    position: absolute;
                    top: 1%;
                    left: 55%;
                }
                
                &-4 {
                    position: absolute;
                    top: 7%;
                    right: 47%;
                }
                
                &-5 {
                    position: absolute;
                    top: 25%;
                    right: 63%;
                    z-index: -1;
                    @include media-breakpoint-up(sm) {
                        right: 55%;
                    }
                    @include media-breakpoint-up(xl) {
                        right: 52%;
                    }
                }
                
                &-6 {
                    position: absolute;
                    top: 16%;
                    left: 51%;
                    @include media-breakpoint-up(xl) {
                        top: 18%;
                        left: 53%;
                    }
                }
                
                &-7 {
                    position: absolute;
                    top: 16%;
                    right: 90%;
                }
                
                &-8 {
                    position: absolute;
                    top: 20%;
                    right: 75%;
                }
                
                &-9 {
                    position: absolute;
                    top: 30%;
                    right: 70%;
                }
                
                &-10 {
                    position: absolute;
                    top: 44%;
                    right: 68%;
                }
                
                &-11 {
                    position: absolute;
                    top: 70%;
                    right: 88%;
                }
                
                &-12 {
                    position: absolute;
                    top: 14%;
                    left: 65%;
                }
                
                &-13 {
                    position: absolute;
                    top: 40%;
                    left: 62%;
                }
                
                &-14 {
                    position: absolute;
                    top: 30%;
                    left: 82%;
                }
                
                &-15 {
                    position: absolute;
                    top: 65%;
                    left: 65%;
                }
                
                &-16 {
                    position: absolute;
                    top: 75%;
                    left: 70%;
                }
            }
        }
    }
}

.settings {
    @media (max-width: 1023px) {
        display: none;
        height: 0;
        visibility: hidden;
    }
    $self: &;
    z-index: 99;
    
    &__block {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        padding: 0 ($default-padding * 2);
        z-index: 1;
        
        &-wrapper {
            height: 97px;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            box-shadow: (0 2px 4px rgba(0, 0, 0, 0.14));
            -webkit-box-shadow: (0 2px 4px rgba(0, 0, 0, 0.14));
            -moz-box-shadow: (0 2px 4px rgba(0, 0, 0, 0.14));
        }
        
        &-logo {
            @include media-breakpoint-up(lg) {
                margin-left: -100px;
            }
            opacity: 0;
            @include transition(all .5s ease);
            
            svg {
                width: 33px;
                height: auto;
                
                g > g {
                    fill: $black;
                }
            }
        }
        
        &-reserve {
            font-family: HaveHeartOne;
            font-size: 1.75rem;
            border-right: 1px solid $border-color;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        
        &-config {
            font-family: Walsheim;
            font-size: 1.125rem;
            line-height: 1.125rem;
            color: $primary;
            border-right: 1px solid $border-color;
            cursor: pointer;
            
            &-icon {
                margin-right: $default-padding;
                
                svg {
                    g > g {
                        fill: $primary;
                    }
                }
            }
            
            &:hover, &:focus, &:active {
                color: $black;
                
                svg {
                    g > g {
                        fill: $black;
                    }
                }
            }
            
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 100%;
                z-index: -1;
                background-color: #DAE3FF;
                @include transition(all 0.15s ease);
            }
            
            &-active {
                color: $black;
                
                svg {
                    g > g {
                        fill: $black;
                    }
                }
                
                &:before {
                    bottom: 0;
                }
            }
        }
    }
    
    &-sticky {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        
        #{ $self } {
            &__block {
                &-logo {
                    opacity: 1;
                }
            }
        }
    }
}