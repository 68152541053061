@font-face {
				font-family: "HaveHeartOne";
				src: url("../fonts/HaveHeartOne/HaveHeartOne.eot"); /* IE9*/
				src: url("../fonts/HaveHeartOne/HaveHeartOne.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
				url("../fonts/HaveHeartOne/HaveHeartOne.woff2") format("woff2"), /* chrome、firefox */
				url("../fonts/HaveHeartOne/HaveHeartOne.woff") format("woff"), /* chrome、firefox */
				url("../fonts/HaveHeartOne/HaveHeartOne.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
				url("../fonts/HaveHeartOne/HaveHeartOne.svg#HaveHeartOne") format("svg"); /* iOS 4.1- */
}

@font-face {
				font-family: "Walsheim";
				src: url("../fonts/Walsheim/GTWalsheimProBold.eot"); /* IE9*/
				src: url("../fonts/Walsheim/GTWalsheimProBold.eot") format("embedded-opentype"), /* IE6-IE8 */
				url("../fonts/Walsheim/GTWalsheimProBold.woff2") format("woff2"), /* chrome、firefox */
				url("../fonts/Walsheim/GTWalsheimProBold.woff") format("woff"), /* chrome、firefox */
				url("../fonts/Walsheim/GTWalsheimProBold.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
				url("../fonts/Walsheim/GTWalsheimProBold.svg") format("svg"); /* iOS 4.1- */
				font-weight: 700;
				font-style: normal
}

/* merriweather-300 - latin */
@font-face {
				font-family: 'Merriweather';
				font-style: normal;
				font-weight: 300;
				src: url('../fonts/Merriweather/merriweather-v20-latin-300.eot'); /* IE9 Compat Modes */
				src: local('Merriweather Light'), local('Merriweather-Light'),
				url('../fonts/Merriweather/merriweather-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				url('../fonts/Merriweather/merriweather-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-300.woff') format('woff'), /* Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
				url('../fonts/Merriweather/merriweather-v20-latin-300.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-regular - latin */
@font-face {
				font-family: 'Merriweather';
				font-style: normal;
				font-weight: 400;
				src: url('../fonts/Merriweather/merriweather-v20-latin-regular.eot'); /* IE9 Compat Modes */
				src: local('Merriweather Regular'), local('Merriweather-Regular'),
				url('../fonts/Merriweather/merriweather-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				url('../fonts/Merriweather/merriweather-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
				url('../fonts/Merriweather/merriweather-v20-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-300italic - latin */
@font-face {
				font-family: 'Merriweather';
				font-style: italic;
				font-weight: 300;
				src: url('../fonts/Merriweather/merriweather-v20-latin-300italic.eot'); /* IE9 Compat Modes */
				src: local('Merriweather Light Italic'), local('Merriweather-LightItalic'),
				url('../fonts/Merriweather/merriweather-v20-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				url('../fonts/Merriweather/merriweather-v20-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-300italic.woff') format('woff'), /* Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
				url('../fonts/Merriweather/merriweather-v20-latin-300italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-italic - latin */
@font-face {
				font-family: 'Merriweather';
				font-style: italic;
				font-weight: 400;
				src: url('../fonts/Merriweather/merriweather-v20-latin-italic.eot'); /* IE9 Compat Modes */
				src: local('Merriweather Italic'), local('Merriweather-Italic'),
				url('../fonts/Merriweather/merriweather-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				url('../fonts/Merriweather/merriweather-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
				url('../fonts/Merriweather/merriweather-v20-latin-italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-700italic - latin */
@font-face {
				font-family: 'Merriweather';
				font-style: italic;
				font-weight: 700;
				src: url('../fonts/Merriweather/merriweather-v20-latin-700italic.eot'); /* IE9 Compat Modes */
				src: local('Merriweather Bold Italic'), local('Merriweather-BoldItalic'),
				url('../fonts/Merriweather/merriweather-v20-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				url('../fonts/Merriweather/merriweather-v20-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-700italic.woff') format('woff'), /* Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
				url('../fonts/Merriweather/merriweather-v20-latin-700italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-700 - latin */
@font-face {
				font-family: 'Merriweather';
				font-style: normal;
				font-weight: 700;
				src: url('../fonts/Merriweather/merriweather-v20-latin-700.eot'); /* IE9 Compat Modes */
				src: local('Merriweather Bold'), local('Merriweather-Bold'),
				url('../fonts/Merriweather/merriweather-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				url('../fonts/Merriweather/merriweather-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-700.woff') format('woff'), /* Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
				url('../fonts/Merriweather/merriweather-v20-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-900 - latin */
@font-face {
				font-family: 'Merriweather';
				font-style: normal;
				font-weight: 900;
				src: url('../fonts/Merriweather/merriweather-v20-latin-900.eot'); /* IE9 Compat Modes */
				src: local('Merriweather Black'), local('Merriweather-Black'),
				url('../fonts/Merriweather/merriweather-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				url('../fonts/Merriweather/merriweather-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-900.woff') format('woff'), /* Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
				url('../fonts/Merriweather/merriweather-v20-latin-900.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* merriweather-900italic - latin */
@font-face {
				font-family: 'Merriweather';
				font-style: italic;
				font-weight: 900;
				src: url('../fonts/Merriweather/merriweather-v20-latin-900italic.eot'); /* IE9 Compat Modes */
				src: local('Merriweather Black Italic'), local('Merriweather-BlackItalic'),
				url('../fonts/Merriweather/merriweather-v20-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				url('../fonts/Merriweather/merriweather-v20-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-900italic.woff') format('woff'), /* Modern Browsers */
				url('../fonts/Merriweather/merriweather-v20-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
				url('../fonts/Merriweather/merriweather-v20-latin-900italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}