@mixin border-radius($radius) {
				-webkit-border-radius: $radius;
				-moz-border-radius: $radius;
				-ms-border-radius: $radius;
				border-radius: $radius;
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
				background: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1-6, Chrome 10+
				background: -o-linear-gradient($deg, $start-color, $end-color); // Opera 12
				background: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}

@mixin transition($args...) {
				-webkit-transition: $args;
				-moz-transition: $args;
				-ms-transition: $args;
				-o-transition: $args;
				transition: $args;
}

@mixin transform($transforms) {
				-moz-transform: $transforms;
				-o-transform: $transforms;
				-ms-transform: $transforms;
				-webkit-transform: $transforms;
				transform: $transforms;
}

@mixin box-shadow($shadow...) {
				-webkit-box-shadow: $shadow;
				-moz-box-shadow: $shadow;
				box-shadow: $shadow;
}

@mixin opacity($opacity) {
				opacity: $opacity;
				$opacity-ie: $opacity * 100;
				filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin flexbox() {
				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
}

@mixin flex($values) {
				-webkit-box-flex: $values;
				-moz-box-flex: $values;
				-webkit-flex: $values;
				-ms-flex: $values;
				flex: $values;
}

@mixin blur($blur) {
				-webkit-filter: blur($blur);
				-moz-filter: blur($blur);
				-o-filter: blur($blur);
				-ms-filter: blur($blur);
				filter: blur($blur);
}