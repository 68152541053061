$black: #322A29;
$white: #FFFFFF;
$blue: #3559FE;
$pink: #F25A7E;
$blue-light: #DAE3FF;
$grey: #DADADA;
$grey-light: #F4F4F4;
$green: #54D69E;
$yellow: #FFF6D6;

$primary: $blue;

$background-color: #F4F4F4;
$font-family: Merriweather, serif;
$headings-font-family: Walsheim;
$default-padding: 16px;
$border-color: #D8D8D8;
$block: 10rem;