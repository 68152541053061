.availability {
				$self: &;
				&__header {
								position: relative;
								padding: 1rem 3rem;
								min-height: 6rem;
								background-color: $green;
								font-family: Walsheim;
								font-weight: 600;
								font-size: 1.5rem;
								line-height: 1.75rem;
								color: $white;
								display: flex;
								align-items: center;
								&__icon {
												margin-right: 2rem;
								}
								&__text {
												margin-top: 5px;
												z-index: 2;
								}
								.triangle {
												z-index: 1;
												opacity: 1;
												width: 2rem;
												height: 2rem;
												position: absolute;
												bottom: -10px;
												left: calc(50% - 1rem);
												
												&:before {
																content: '';
																width: 2rem;
																height: 2rem;
																display: block;
																background: $green;
																transform: rotate(45deg);
												}
								}
				}
				&__content {
								&__block {
												padding: 2rem 1rem;
												@include media-breakpoint-up(lg) {
																padding: 3rem;
												}
												&:not(:last-child) {
																border-bottom: 1px solid $border-color;
												}
								}
				}
				&__item {
								display: flex;
								align-items: center;
								margin-bottom: 2rem;
								&__icon {
												margin-right: 1rem;
												@include media-breakpoint-up(lg) {
																margin-right: 2.5rem;
												}
												svg {
																width: 1.375rem;
																height: auto;
																g > g {
																				fill: #262626;
																}
												}
								}
								&__avatar {
												width: 2rem;
												height: auto;
												border-radius: 50%;
												margin-right: .375rem;
												@include media-breakpoint-up(lg) {
																margin-right: 1.875rem;
												}
								}
								&__title {
												font-family: Walsheim;
												font-size: 1.125rem;
												margin-bottom: 1rem;
												border-bottom: 1px solid $border-color;
								}
								&__text {
												display: flex;
												align-items: center;
												flex: 1 0 0px;
												&__items {
																display: flex;
																align-items: center;
												}
								}
								&__price {
												font-weight: 600;
								}
								&__incl {
												font-weight: 600;
												color: $green;
								}
								&__persons {
												font-style: italic;
												color: rgba(#262626, 0.64);
								}
								&__btn {
												display: inline-flex;
												flex: 0 0 20px;
												height: 20px;
												width: 20px;
												border-radius: 50%;
												background-color: $pink;
												align-items: center;
												justify-content: center;
												margin-right: 1rem;
												cursor: pointer;
												@include transition(all .3s ease);
												&:hover, &:focus, &:active {
																background-color: $black;
												}
												svg {
																width: 12px;
																height: auto;
																margin-bottom: 3px;
																transform: rotate(45deg);
																g > g {
																				fill: $white;
																}
												}
								}
								&-request {
												color: rgba(#262626, 0.64);
												svg {
																g > g {
																				fill: #DADADA;
																}
												}
								}
								
				}
				&__summary {
								text-align: right;
								&__total {
												font-weight: 600;
												line-height: 2rem;
												margin-bottom: .5rem;
												&-label {
																margin-right: .5rem;
												}
								}
								&__text {
												font-size: 0.875rem;
												font-style: italic;
												line-height: 1.75rem;
												margin-bottom: 1rem;
												opacity: .64;
								}
								#{ $self } {
												&__summary {
																&__btn {
																				font-family: Walsheim;
																				padding: .85rem 2rem 1rem;
																}
												}
								}
				}
}