.mobile__menu {
				$self: &;
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				pointer-events: none;
				visibility: hidden;
				overflow: hidden;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				outline: 1px solid transparent;
				display: flex;
				align-items: center;
				justify-content: center;
				z-index: 99;
				&__cfg {
								position: absolute;
								top: 0;
								bottom: 0;
								left: 100vw;
								z-index: 105;
								transition: all .5s ease;
								overflow-y: scroll;
								&__header {
												position: fixed;
												z-index: 1;
												width: 100vw;
												background: $white;
												display: flex;
												justify-content: space-between;
												align-items: center;
												padding: .5rem 1rem;
												height: 60px;
												font-family: Walsheim;
												border-bottom: 1px solid $border-color;
												&-close {
																cursor: pointer;
																svg {
																				@include transform(rotate(45deg))
																}
												}
								}
								&__wrapper {
												position: relative;
												width: 100vw;
												height: 100vh;
								}
								&__content {
												&-item {
																padding: calc(60px + 1rem) 1rem 1rem;
																background: $white;
												}
								}
				}
				&__wrapper {
								width: 250vw;
								height: 250vw;
								background: $white;
								transition: all 0.4s ease;
								flex: none;
								-webkit-transform: scale(0);
								transform: scale(0);
								-webkit-backface-visibility: hidden;
								backface-visibility: hidden;
								overflow: hidden;
								display: flex;
								align-items: center;
								justify-content: center;
								@include border-radius(50%);
				}
				&__container {
								position: relative;
								text-align: center;
								width: 100vw;
								max-width: 100vw;
								height: 100vh;
								max-height: 100vh;
								opacity: 0;
								transition: opacity 0.4s ease;
								overflow-x: hidden;
								opacity: 0;
				}
				&-active {
								pointer-events: auto;
								visibility: visible;
								#{ $self } {
												&__wrapper {
																-webkit-transform: scale(1);
																transform: scale(1);
																transition-duration: 0.75s;
												}
												&__container {
																opacity: 1;
																transition: opacity 0.4s ease 0.4s;
												}
												&__cfg {
																&-active {
																				left: 0;
																}
												}
								}
				}
				&__header {
								background: $white;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding: .5rem 1rem;
								height: 60px;
								font-family: Walsheim;
								border-bottom: 1px solid $border-color;
								&-logo {
												cursor: pointer;
												svg {
																width: 33px;
																height: auto;
																
																g > g {
																				fill: $black;
																}
												}
								}
								&-close {
												cursor: pointer;
												svg {
																@include transform(rotate(45deg))
												}
								}
				}
				&__content {
								&-item {
												padding: 1rem;
												background: $white;
												.datepicker {
																box-shadow: none;
																-webkit-box-shadow: none;
																-moz-box-shadow: none;
																.DayPicker {
																				padding: 0;
																}
												}
												.btn-availability-wrapper {
																margin:0;
																text-align: center;
																.btn {
																				display: block;
																}
												}
								}
				}
				&__link {
								position: relative;
								color: $blue;
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding: .5rem 1rem;
								height: 60px;
								font-family: Walsheim;
								border-bottom: 1px solid $border-color;
								svg {
												width: 15px;
												height: auto;
												g > g {
																fill: $blue;
												}
								}
								&:hover, &:focus, &:active {
												color: $black;
												
												svg {
																g > g {
																				fill: $black;
																}
												}
								}
								
								&:before {
												content: "";
												position: absolute;
												top: 0;
												left: 0;
												right: 0;
												bottom: 100%;
												z-index: -1;
												background-color: #DAE3FF;
												@include transition(all 0.15s ease);
								}
								
								&-choosen {
												color: $black;
												
												svg {
																g > g {
																				fill: $black;
																}
												}
												
												&:before {
																bottom: 0;
												}
								}
				}
}