.cookies {
				position:fixed;
				left: 15px;
				right: 15px;
				bottom: 15px;
				padding: 1rem;
				background: rgba($white,.9);
				z-index: 99;
				max-width: 100%;
				box-shadow: (0px 2px 4px rgba($black, 0.5));
				-webkit-box-shadow: (0px 2px 4px rgba($black, 0.5));
				-moz-box-shadow: (0px 2px 4px rgba($black, 0.5));
				opacity: 1;
				text-align: center;
				@include border-radius(4px);
				@include transition(all .5s ease);
				&.dismiss {
								opacity: 0;
								display: none;
				}
				&__title {
								font-family: Walsheim;
								font-size: 1rem;
								font-weight: 600;
								margin-bottom: 5px;
				}
				
				&__text {
								font-size: .75rem;
								line-height: 1.6rem;
								margin: 0 auto .5rem;
								max-width: 50rem;
								&-link {
												color: $black;
												opacity: .75;
												&:hover {
																opacity:1;
																color: $black;
																text-decoration: underline;
												}
								}
				}
				
				&__btn {
								font-size: .85rem;
								padding: .85rem 1.5rem;
								&-wrapper {
												display: flex;
												justify-content: center;
												align-items: center;
												margin-top: 1rem;
								}
				}
				
				&__link {
								font-size: .65rem;
								color: $black;
								opacity: .5;
								padding: 0 1rem;
								&:hover {
												opacity:1;
												color: $black;
												text-decoration: underline;
								}
				}
}