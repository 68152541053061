.privacy {
				$self: &;
				&__content {
								&__block {
												padding: 2rem 1rem;
												@include media-breakpoint-up(lg) {
																padding: 3rem;
												}
												&:not(:last-child) {
																border-bottom: 1px solid $border-color;
												}
								}
				}
}