.cfg {
				$self: &;
				
				&__option {
								&-wrapper {
												max-width: 320px;
												margin: 0 auto;
												padding: 0;
												@media (width: 1024px) {
																max-width: 31.33333%;
																.cfg__option-table-title {
																				padding-left: 1.5rem;
																}
												}
												
												&-extended {
																margin: 0 auto 5rem;
																
																#{ $self } {
																				&__option {
																								&-table {
																												color: $white;
																												border: 6px solid $white;
																												
																												&-title {
																																min-height: 200px;
																																flex-direction: column;
																																justify-content: center;
																																text-align: center;
																																padding: 0;
																																border-bottom: 6px solid $white;
																												}
																												
																												&-icon {
																																margin: 0 0 .5rem;
																												}
																												
																												&-text {
																																font-size: 2rem;
																												}
																												
																												&-range, &-price {
																																font-size: 1.5rem;
																												}
																												
																												&-range {
																																border-right: 3px solid $white;
																												}
																												
																												&-price {
																																border-left: 3px solid $white;
																												}
																												
																												svg {
																																g > g {
																																				fill: $white;
																																}
																												}
																												
																												&-active {
																																#{ $self } {
																																				&__option {
																																								&-table {
																																												&-title {
																																																background-color: $white;
																																																color: $primary;
																																																border: none;
																																																margin-bottom: 6px;
																																																padding: .5rem 0 0;
																																												}
																																												
																																												&-range {
																																																background-color: $white;
																																																color: $primary;
																																																border: none;
																																																border-bottom: 3px solid $white;
																																																margin-right: 3px;
																																																width: calc(50% - 3px);
																																												}
																																												
																																												&-price {
																																																background-color: $white;
																																																color: $primary;
																																																border: none;
																																																border-bottom: 3px solid $white;
																																																margin-left: 3px;
																																																width: calc(50% - 3px);
																																												}
																																								}
																																				}
																																}
																																
																																svg {
																																				g > g {
																																								fill: $primary;
																																				}
																																}
																												}
																								}
																				}
																}
												}
								}
								
								&-table {
												width: 100%;
												font-family: Walsheim;
												margin-bottom: 2rem;
												cursor: pointer;
												color: $primary;
												border: 4px solid $primary;
												
												&-title {
																display: flex;
																min-height: 125px;
																align-items: center;
																padding: 1rem 2rem 1rem 3rem;
																line-height: 1.2;
																border-bottom: 4px solid $primary;
												}
												
												&-icon {
																margin-right: 2rem;
												}
												
												&-text {
																font-size: 1.5rem;
																font-family: Walsheim;
												}
												
												&-range {
																text-align: center;
																border-right: 2px solid $primary;
												}
												
												&-price {
																text-align: center;
																border-left: 2px solid $primary;
												}
												
												&-range, &-price {
																font-size: 1.125rem;
																display: inline-block;
																width: 50%;
																padding: 1.5rem 0;
												}
												
												svg {
																g > g {
																				fill: $primary;
																}
												}
												
												&-active {
																&#{ $self }__option-table {
																				border: none
																}
																
																#{ $self } {
																				&__option {
																								&-table {
																												&-title {
																																background-color: $primary;
																																color: $white;
																																border-color: $white;
																																border: none;
																																margin-bottom: 4px;
																																padding: 1.3rem 2rem 1rem 3rem;
																												}
																												
																												&-range, &-price {
																																padding: 1.6rem 0;
																												}
																												
																												&-range {
																																background-color: $primary;
																																color: $white;
																																border-color: $white;
																												}
																												
																												&-price {
																																background-color: $primary;
																																color: $white;
																																border-color: $white;
																												}
																								}
																				}
																}
																
																svg {
																				g > g {
																								fill: $white;
																				}
																}
																
																&-icon {
																				position: absolute;
																				top: 1.5rem;
																				right: 1.5rem;
																}
												}
								}
								
								&-modal {
												position: absolute;
												left: 0;
												right: 0;
												z-index: 3;
												@include transition(all .5s ease);
												
												.triangle {
																z-index: -1;
												}
												
												&.active {
												}
												
												&-top {
																bottom: 97px;
																opacity: 0;
																padding-bottom: 2rem;
																visibility: hidden;
																&.active {
																				opacity: 1;
																				visibility: visible;
																				.triangle {
																								z-index: 1;
																								opacity: 1;
																								width: 3rem;
																								height: 3rem;
																								position: absolute;
																								bottom: 10px;
																								left: 45%;
																								
																								&:before {
																												content: '';
																												width: 3rem;
																												height: 3rem;
																												display: block;
																												background: $white;
																												transform: rotate(45deg);
																												box-shadow: (0 2px 4px rgba(0,0,0, 0.14));
																												-webkit-box-shadow: (0 2px 4px rgba(0,0,0, 0.14));
																												-moz-box-shadow: (0 2px 4px rgba(0,0,0, 0.14));
																								}
																								
																								&:after {
																												content: '';
																												z-index: 1;
																												position: absolute;
																												width: 5rem;
																												height: 2.5rem;
																												display: block;
																												bottom: 1.375rem;
																												right: -1rem;
																												background: $white;
																								}
																				}
																}
												}
												
												&-bottom {
																top: -100vh;
																bottom: 100%;
																z-index: -1;
																opacity: 0;
																padding-top: 2rem;
																visibility: hidden;
																&.active {
																				top: 97px;
																				bottom: 0;
																				opacity: 1;
																				visibility: visible;
																				.triangle {
																								z-index: 1;
																								opacity: 1;
																								width: 3rem;
																								height: 3rem;
																								position: absolute;
																								top: 10px;
																								left: 45%;
																								
																								&:before {
																												content: '';
																												width: 3rem;
																												height: 3rem;
																												display: block;
																												background: $white;
																												transform: rotate(45deg);
																												box-shadow: (0 2px 4px rgba(0,0,0, 0.14));
																												-webkit-box-shadow: (0 2px 4px rgba(0,0,0, 0.14));
																												-moz-box-shadow: (0 2px 4px rgba(0,0,0, 0.14));
																								}
																								
																								&:after {
																												content: '';
																												z-index: 1;
																												position: absolute;
																												width: 5rem;
																												height: 2.5rem;
																												display: block;
																												top: 1.375rem;
																												right: -1rem;
																												background: white;
																								}
																				}
																}
												}
												
												.container {
																z-index: 1;
																background-color: $white;
																padding: 2.5rem 2rem;
																box-shadow: (0 2px 4px rgba(0,0,0, 0.14));
																-webkit-box-shadow: (0 2px 4px rgba(0,0,0, 0.14));
																-moz-box-shadow: (0 2px 4px rgba(0,0,0, 0.14));
												}
								}
				}
				
				&__link {
								font-family: Walsheim;
								color: $pink;
								
								&:hover, &:focus, &:active {
												color: $pink;
												text-decoration: none;
								}
								
								&-wrapper {
												text-align: center;
								}
								
								&-icon {
												margin-left: 1rem;
												
												svg {
																g > g {
																				fill: $pink;
																}
												}
								}
				}
}